import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate ,NavLink} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-date-picker';
import axios from 'axios'
import dateFormat from "dateformat";
import { motion } from 'framer-motion';
// import Navbar from './Navbar';
// import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from './AudioPlayer';
import Swal from 'sweetalert2';
import { RotatingLines } from 'react-loader-spinner';



const AgentPerformance = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])


  useEffect(() => {
    document.title = "Performance"
 }, []);
 

  const [startdate, setstartdateval] = useState(new Date());

  const [enddate, setEnddateval] = useState(new Date());

  const [sddate, setsddate] = useState("NA");
  const [eddate, seteddate] = useState("NA");


  const [campaignname,setCampaignname] = useState("NA");

  const [keypress,setKeypress] = useState("ALL");

  const [opennav, setopennav] = useState();
  const[listname,setList] = useState();


  const setstartdate = (event) => {
      //alert(event)
    setstartdateval(event)
    var sttt=dateFormat(event, "yyyy-mm-dd")

 

  setsddate(sttt)
  


}


const[downloadtrue ,setDownloadtrue] =useState(false)
const filterreportdownload =async() =>{
  setDownloadtrue(true)
  try {
    const response = await fetch(process.env.REACT_APP_IP + `/User/PerformanceReport?username=${campaignname}&startdate=${dateFormat(startdate, "yyyy-mm-dd")}&enddate=${dateFormat(enddate, "yyyy-mm-dd")}`, {
        method: 'GET',
        headers: {
           // 'Authorization': `Bearer ${token}`, // Add your token to the request headers
           "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
    });

    if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    } else {
        console.error('Error downloading CSV:', response.status);
        setDownloadtrue(false)
    }
    setDownloadtrue(false)
} catch (error) {
    console.error('Error downloading CSV:', error);
    setDownloadtrue(false)
}


}

const optionforuser = async (e) => {
  
  const respp = await fetch(process.env.REACT_APP_IP+"/Admin/RestAllUserdrop", {
    method: 'POST',
    // headers: {
    //     'Content-Type': 'application/json'
    // },

    headers: {
      'Content-Type': 'application/json',

      "Authorization": `Bearer ${localStorage.getItem('token')}`
    }

    // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
  })
  const json = await respp.json()
  console.log(json.length)

  var elelist = document.getElementById('campnameid');

  elelist.innerHTML = ""+('<option value="NA">ALL</option>');
  for (var i = 0; i < json.length; i++) {
    // POPULATE SELECT ELEMENT WITH JSON.
    elelist.innerHTML = elelist.innerHTML +
      '<option  value="' + json[i]['username'] + '">' + json[i]['id'] + ' | ' + json[i]['username'] + '</option>';
  }

 

  


}

useEffect(() => {
    optionforuser()
}, [])

const setenddate = (event) => {
    setEnddateval(event)
    var enddd=dateFormat(event, "yyyy-mm-dd")
    seteddate(enddd)

}

  const toggleClass = () => {
    //alert("clicked");
    if (!opennav) {
      setopennav(true);
    } else {
      setopennav(false);
    }
    // alert("clicked"+opennav);

  }

  

  const filterreportsubmit =  async (event) => {
    event.preventDefault();
    //const DATE_FORMATER = require( 'dateformat' );
    //var datetimef = DATE_FORMATER( startdate, "yyyy-mm-dd" );
  console.log(">>>>startdate>>>>>>>"+dateFormat(startdate, "yyyy-mm-dd")+">>>>>enddate>>>>>>>"
  +dateFormat(enddate, "yyyy-mm-dd")+">>>>>>campaignname>>>>>>"+campaignname+">>>>>>keypress>>>>>>"+keypress);
  
  getAllPosts()

  }

  const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
        <div className='mybgcolor'>
        <motion.div
    // style={pageStyle}
    // initial={{ x: 300, opacity: 0 }}
    // // animate={{ x: 0, opacity: 1,rotate: 360}}
    // animate={{ x: 0, opacity: 1}}
    // exit={{ rotate: 360}}
    // transition={{
    //   delay: 1,
    //   x: { type: "spring", stiffness: 100 },
    //   default: { duration: 2 },
    // }}
    // variants={pageVariants}
    // transition={pageTransition}
    initial={{ rotate: 270 }}

    //  animate={{ x: 0 }}
     animate={{ x: 0, opacity: 2.0,rotate: 360}}
     transition={{ duration: 0.5}}

  >
          <div className="table-responsive p-1">
          <table className="table table-hover table-bordered checkoddata ignoreline">
              <thead className="bg-darkblue text-white">
                <tr>
                  {/* <th>ID</th> */}

                  
                  <th>Date</th>
                  <th>UserId</th>
                  <th>UserName</th>
                  <th>Login Time</th>
                  <th>Total Login</th>
                  <th>Total TalkTime</th>
                  <th>Total WrapupTime</th>
                  <th>TotalCall</th>
                  <th>TotalBreak</th>
                  <th>TotalBreak-Time</th>
                  

                </tr>
              </thead>
              <tbody>
                {data.content.map(page => {
                  return (
                    <tr key={page.id} style={{whiteSpace:"nowrap"}}>
                      {/* <td>{page.id}</td> */}
                      <td>{page.reportdate}</td> 
                      <td>{page.userid}</td>   
                      <td> {page.username}</td>  
                      <td>{page.firstlogintime}</td>  
                      <td>{page.totallogintime}</td>  
                           
                      <td>{page.totaltalktime}</td>    
                      <td>{page.totalwraptime}</td>   
                      <td>{page.totalcall}</td>    
                      <td>{page.totalbreak}</td>  
                      <td>{page.totalbreaktime}</td>                              

                    </tr>
                  );
                })}
              </tbody>
            </table>

           
            {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
          </div>
          </motion.div>
        </div>
      </>

    )

  }

  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);
   // alert(">>>>"+campaignname)

    const res = await axios.get(process.env.REACT_APP_IP+`/User/AgentPerformance?pageNumber=${offset - 1}&&
    size=10&&startdate=${sddate}&&enddate=${eddate}&&keypress=${keypress}&&username=${campaignname}`,
     { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts()
  }, [offset])




  const testdata = async(e) => {

    console.log(">>>>>>>>>>>>>")
    setCampaignname(e.target.value)
    


  }


  return (

    <>
    {/* <Navbar /> */}
   
    {/* <div className='row topmargin'> */}
    <div className='row '>

    <ul className="nav nav-tabs ">
  <li className="nav-item mx-1">
      <NavLink className="nav-link mybg-dark text-black"  to="/Report"><b>All Report</b></NavLink>
  </li>
  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/FilterReport"><b>Filter Report</b></NavLink>
  </li>

  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/Agentlog"><b>Agent Log</b></NavLink>
  </li>
  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/AgentPerformance"><b>AgentPerformance</b></NavLink>
  </li>
  
</ul>




      <div className={!opennav ? "col-md-12 col-12  my-2" : "col-md-12 col-12  p-3 my-2"}>



        <div className='row mt-2'>
        <div className='col-md-3'>
             <div className='card '>
                 <form onSubmit={filterreportsubmit} >
                  <div className='card-header'>
                  <h4 className='text-center'>Agent-Performance</h4>
                  </div>
                    

                     {/* <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Campaign Name</label>
                        <select className="form-select" name="campaignname" 
                            onChange={(e) => setCampaignname(e.target.value)} >
                            <option value="All">ALL</option>
                           
                        </select>

                    </div> */}
                    <div className=" mb-2 mt-2">
                          <label htmlFor="campaignname" className="form-label">Agent Name</label>
                         {/* <select id="campnameid" className="form-select" name="campaignname"  onChange={(e) => setCampaignname(e.target.value)} required>
                            <option value="">-- Select Campaign --</option>
                            

                        </select> */}

                       <select id="campnameid" className="form-select" name="campaignname"  onChange={testdata} required>
                            <option value="">-- Select Agent --</option>
                            

                        </select>
                       
                    </div>

                    {/* <div className="mb-3 mt-2">
                        <label  className="form-label">KeyPress</label>
                        <select className="form-select" name="keypress" value={keypress}
                            onChange={(e) => setKeypress(e.target.value)} >
                            <option value="All">ALL</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                           
                        </select>

                    </div> */}

                    <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Start Date</label>
                       
                        <DatePicker className="form-control" onChange={setstartdate} value={startdate} />

                    </div>

                    <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">End Date</label>
                       
                        <DatePicker className="form-control" onChange={setenddate} value={enddate} />

                    </div>

                    <div className="d-grid gap-2 mb-1 mt-2">
                         <input className="btn btn-primary " type="submit" value="Submit" />
                    </div>
                    </form>

             </div>
             
                     
                     
                   

             



        </div>
        
        <div className='col-md-9'>
              <div className={downloadtrue ? "text-center mt-5" : "text-center mt-5 hide"}>
                  <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="200"
                      visible={true}
                    />

              </div>
                <div className="">
                  <div className=' card-header'>
                    <div className='row'>
                                  
                                  <div className='col-md-12  text-center '>
                                         <h2>Agent-Performance Report</h2>
                                  </div>

                    </div>
                                
                  </div>
                                  
                                 
                  
               
                {/* Display all the posts */}
                {posts}

                {/* Using React Paginate */}
               
                </div>

                
                <div className='row mb-5'>
                <div className='col-md-12'>
                  <div className='mt-1 mb-1 ms-1 '>
                      <button className='btn btn-primary btn-mysm me-3' onClick={filterreportdownload}>
                        Download
                        </button>

                        GotoPage:<input type="text" className="col-md-3 mx-2 " id="gotopage" aria-describedby="gotopage"
                  placeholder="gotopage" onChange={onInputChange} />
                  </div>
                  
                </div>
                <div className='col-md-3'></div>
<div className='col-md-6 mt-3'>
<ReactPaginate
     previousLabel={<i style={{color:'blue'}} className="fa fa-arrow-left fa-lg"></i>}
     nextLabel={<i style={{color:'blue'}} className="fa fa-arrow-right fa-lg"></i>}
    breakLabel={".."}
    breakClassName={"break-me"}
    breakLinkClassName={"break-mef"}
    pageCount={pageCount}
    onPageChange={handlePageClick}
    forcePage={offset - 1}

    containerClassName={"pagination"}
    subContainerClassName={"pages pagination"}
    activeClassName={"active"} />

</div>
</div>


        </div>
        

</div>
      </div>




    </div>
    </>
  )
}

export default AgentPerformance
