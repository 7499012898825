
import { useEffect,useState } from 'react'
import axios from 'axios';

import { useNavigate,Link, NavLink } from "react-router-dom";
import { FaBars,FaUserPlus, FaHome, FaLock, FaMoneyBill, FaUser,FaUsers } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";

import { RiLoginCircleLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";
import { MdAudiotrack } from "react-icons/md";
import { MdOutlineAudioFile } from "react-icons/md";

import { AiFillHeart, AiTwotoneFileExclamation,AiOutlineForm } from "react-icons/ai";
import { BsDatabaseFillDown } from "react-icons/bs";

import { BsCartCheck } from "react-icons/bs";
import { AiOutlineLogout } from "react-icons/ai";
import { IoMdTimer } from "react-icons/io";
import { FcCallback } from "react-icons/fc";


import { MdCampaign } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { FcManager } from "react-icons/fc";


import logo from '../../images/profile.png';
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { PiPasswordFill, PiTreeStructureFill } from "react-icons/pi";

import { RiLockPasswordFill } from "react-icons/ri";

import { MdPostAdd } from "react-icons/md";
import { FaSms } from "react-icons/fa";



//localStorage.getItem('roles') === 'ROLE_ADMIN'?`name: "Users"`:`name: "Users"`







// useEffect(() => {
  
// }, [])



const styleObj = {
  textDecoration: "none !important"
}



const SideBar = ({ children }) => {



  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const checkMobile = () => {
      setIsOpen(window.innerWidth > 768);
    };

    checkMobile();

   

    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, [location.pathname]);



  const userInfofun = async () => {  

    if (localStorage.getItem('token'))
    {
      const userinfo = await fetch(process.env.REACT_APP_IP+"/User/GetUserinfo", {
        method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json'
        // },
  
        headers: {
          'Content-Type': 'application/json',
  
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
  
        // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
      })
      const json = await userinfo.json();
      // setUsernamed(json.username);
      if (json.userpro.loginstatus === "LOGOUT")
      {
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        setLogin(true)
        setSidebar(false)
       setCredentials({username: "", password: ""})
      // setCredentials({ ...credentials, '': '' })
    
        history('/Dashboard');
        //window.location.reload();
      
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: "Successfully Logout",
        background: '#e9ecef',
       // title: 'Done Successfully ',
       //background: primary,
        showConfirmButton: false,
        timer: 2500
        
      })

      }
  }
}

useEffect(() => {
 // Fetch data every 5 seconds
 const interval = setInterval(userInfofun, 10000);
  
 // Clean up the interval when the component unmounts
 return () => {
   clearInterval(interval);
 };
// totalcallbackfun()
}, [])



// Get CallBack Notification

const [totalcallback,setTotalcallback] = useState()

const totalcallbackfun = async () => {  

      if (localStorage.getItem('token'))
      {
      const res = await axios.get(process.env.REACT_APP_IP+`/User/CountActiveCallBack`,
        {
          headers: { 
            "Authorization": `Bearer ${localStorage.getItem('token')}` 
          } 
        })

      const data = res.data;
      if(data>0)
      {
        setShowNotification(true);
      }else{
        setShowNotification(false);
      }
      setTotalcallback(data)
    }
}

useEffect(() => {
   // Fetch data every 5 seconds
   const interval = setInterval(totalcallbackfun, 10000);
    
   // Clean up the interval when the component unmounts
   return () => {
     clearInterval(interval);
   };
 // totalcallbackfun()
}, [])



  const [showNotification, setShowNotification] = useState(false);

  // useEffect(() => {
  //   setShowNotification(true);

  //   // setTimeout(() => {
  //   //   setShowNotification(false);
  //   // }, 3000);
  // },[])




  const[usernamed, setUsernamed] = useState('')

useEffect( async() => {

  if (localStorage.getItem('token')) 
    {

    const userinfo = await fetch(process.env.REACT_APP_IP+"/User/GetUserinfo", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await userinfo.json();
     setUsernamed(json.username);
    //  setcamp(json.userpro.campaign);
    //  setsip(json.userpro.sip)
    //  setcustmobile(json.userpro.custmobile)
    //  setloginstatus(json.userpro.loginmode)
    //  setType(json.userpro.type)  
  }
}, [])


  const[mmuser,setUser] = useState("")
useEffect(() => {

  if (localStorage.getItem('roles')==='ROLE_ADMIN')
   {
    setUser("Users")

  } else {
    setUser("")
}
  
  
  
}, [])
// Admin role
const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/Realtime",
    name: "Realtime",
    icon: <IoMdTimer />,
  },
   
   {
    

     path: "/Users",
    // path: mmuser,
     name: "Agents",
     icon: <FaUserPlus />,
    // icon: mmuser,
  },

  {
    path: "/LeadsManager",
    name: "LeadsManager",
    icon: <FcManager />,
    exact: true,
    subRoutes: [
     
      {
        path: "/LeadsManager",
        name: "LeadsManager",
        icon: <FcManager />,
      },
      {
        path: "/AddLeadStatus",
        name: "AddLeadStatus",
        icon: <MdPostAdd />
      }
    ]
  },
  
 {
  path: "/AddCampaign",
  name: "Campaign",
  icon: <MdCampaign />,
  exact: true,
  subRoutes: [
   
    {
      path: "/AddCampaign",
      name: "AddCampaign",
      icon: <AiOutlineForm />,
    },
    {
      path: "/Makegui",
      name: "MakeCrm",
      icon: <AiOutlineForm />,
    },
    {
      path: "/Assignlist",
      name: "AssignList",
      icon: <AiOutlineForm />,
    },
    {
      path: "/Assignagent",
      name: "Assignagent",
      icon: <AiOutlineForm />,
    },
    {
      path: "/CampDid",
      name: "CampDid",
      icon: <AiOutlineForm />,
    },
    {
      path: "/AddDisposition",
      name: "Disposition",
      icon: <AiOutlineForm />,
    },
    {
      path: "/ChurnLeads",
      name: "ChurnLeads",
      icon: <AiOutlineForm />,
    },
    
  ],
},

{
  path: "/Callbackreport",
  name: "CallBackReport",
  icon: <BsDatabaseFillDown />,
  exact: true,
  subRoutes: [
   
    {
      path: "/Callbackreport",
      name: "Callback Report",
      icon: <FcCallback />,
    }
    
  ]
},


  {
    path: "/Report",
    name: "Reports",
    icon: <BsDatabaseFillDown />,
    exact: true,
    subRoutes: [
      
      {
        path: "/Reportlive",
        name: "Reportlive",
        icon: <TbReportSearch />,
      },
      {
        path: "/Report",
        name: "AllReports",
        icon: <TbReportSearch />,
      },
      {
        path: "/FilterReport",
        name: "FilterReport",
        icon: <TbReportSearch />,
      },
      {
        path: "/Agentlog",
        name: "Agentlog",
        icon: <TbReportSearch />,
      },
      {
        path: "/AgentPerformance",
        name: "AgentPerformance",
        icon: <TbReportSearch />,
      },
      
    ],
  },

  // {
  //   path: "/messages",
  //   name: "Messages",
  //   icon: <MdMessage />,
  // },
  // {
  //   path: "/analytics",
  //   name: "Analytics",
  //   icon: <BiAnalyse />,
  // },
  // {
  //   path: "/file-manager",
  //   name: "Report",
  //   icon: <AiTwotoneFileExclamation />,
  //   subRoutes: [
  //     {
  //       path: "/CallLog",
  //       name: "CallLog ",
  //       icon: <FaUser />,
  //     },
  //     {
  //       path: "/FilterCallLog",
  //       name: "FilterCallLog ",
  //       icon: <FaUser />,
  //     },
  //   ],
  // },
  // {
  //   path: "/order",
  //   name: "Order",
  //   icon: <BsCartCheck />,
  // },
  {
    path: "/settings",
    name: "Settings",
    icon: <BiCog />,
    exact: true,
    subRoutes: [

      {
        path: "/UploadAudio",
        name: "UploadAudio",
        icon: <BiCog />,
      },
      {
        path: "/UploadNumber",
        name: "UploadNumber",
        icon: <BiCog />,
      },
      {
        path: "/BlockNumber",
        name: "BlockNumber",
        icon: <BiCog />,
      },
      {
        path: "/ChangePwd",
        name: "Pwdchange",
        icon: <FaLock />,
      },
     
      {
        path: "/AllowIP",
        name: "AllowIP",
        icon: <BiCog />,
      },
       
      {
        path: "/Break",
        name: "BreakSetting",
        icon: <BiCog />,
      },
      
      
    ],
  },

  {
    path: "/Smssuits",
     name: "SMS Suits",
     icon: <FaSms size="24" />,
    exact: true,
    subRoutes: [
      {
    

        path: "/Smssuits",
       // path: mmuser,
        name: "SMS Suits",
        icon: <FaSms size="24" />,
       // icon: mmuser,
      },
      {
        path: "/SmsportSetting",
        name: "SmsportSetting",
        icon: <BiCog />,
      },
    ],

  },
  
  

  
{
    

  path: "/AddAgent",
 // path: mmuser,
  name: "IvrAgent",
  icon: <FaUserPlus />,
 // icon: mmuser,
},
{
    

  path: "/DrawIvr",
 // path: mmuser,
  name: "DrawIvr",
  icon: <PiTreeStructureFill />,
 // icon: mmuser,
},

{
  path: "/Logout",
  name: "Logout",
  icon: <AiOutlineLogout />,
},
];
 






const routessuperadmin = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome />,
  },
   
   {
    

     path: "/users",
    // path: mmuser,
    name: "Clients",
     icon: <FaUserPlus />,
    // icon: mmuser,
  },
  // {
  //   path: "/messages",
  //   name: "Messages",
  //   icon: <MdMessage />,
  // },
  // {
  //   path: "/analytics",
  //   name: "Analytics",
  //   icon: <BiAnalyse />,
  // },
  {
    path: "/file-manager",
    name: "Report",
    icon: <AiTwotoneFileExclamation />,
    subRoutes: [
      {
        path: "/Reportlive",
        name: "Reportlive",
        icon: <TbReportSearch />,
      },
      {
        path: "/Report",
        name: "AllReports",
        icon: <TbReportSearch />,
      },
      // {
      //   path: "/FilterReport",
      //   name: "FilterReport",
      //   icon: <TbReportSearch />,
      // },
      {
        path: "/Agentlog",
        name: "Agentlog",
        icon: <TbReportSearch />,
      },
      {
        path: "/Sharescreen",
        name: "Sharescreen",
        icon: <TbReportSearch />,
      },
    ],
  },
  // {
  //   path: "/order",
  //   name: "Order",
  //   icon: <BsCartCheck />,
  // },
  {
    path: "/settings",
    name: "Settings",
    icon: <BiCog />,
    exact: true,
    subRoutes: [
      {
        path: "/SmsportSetting",
        name: "SmsportSetting",
        icon: <BiCog />,
      },
      {
        path: "/ChannelSetting",
        name: "ChannelSetting",
        icon: <BiCog />,
      },

      {
        path: "/AssignDid",
        name: "AssignDid",
        icon: <BiCog />,
      },

      
      // {
      //   path: "/Audio",
      //   name: "AUDIO",
      //   icon: <FaLock />,
      // },
      {
        path: "/UploadAudio",
        name: "ApproveAudio",
        icon: <MdOutlineAudioFile />,
      },

      {
        path: "/ChangePwd",
        name: "Pwdchange",
        icon: <FaLock />,
      },
      
    ],
  },
  {
    path: "/Logout",
    name: "Logout",
    icon: <AiOutlineLogout />,
  },
  // {
  //   path: "/saved",
  //   name: "Saved",
  //   icon: <AiFillHeart />,
  // },
];






const routagent = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaHome />,
  },
  
  {
    path: "/file-manager",
    name: "Report",
    icon: <AiTwotoneFileExclamation />,
    subRoutes: [
      {
        path: "/CallLog",
        name: "CallLog ",
        icon: <FaUser />,
      },
      {
        path: "/FilterCallLog",
        name: "FilterCallLog ",
        icon: <FaUser />,
      },
    ],
  },
  {
    path: "/ChangePwd",
    name: "Pwdchange",
    icon: <FaLock />,
  },
  {
    path: "/Dashboard",
    name: "Logout",
    icon: <AiOutlineLogout />,
  },
];






const routesclient = [
  {
    path: "/Crm",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/CallBack",
    name: "TodayCallBack",
    icon: <FcCallback />,
  },

  // {
  //   path: "/CallBackall",
  //   name: "AllCallBack",
  //   icon: <FcCallback />,
  // },

  {
    path: "/Agentcalllog",
    name: "Agentcalllog",
    icon: <TbReportSearch />,
  }, 
  {
    path: "/LeadsManager",
    name: "LeadsManager",
    icon: <FcManager />,
  },
  {
    path: "/Dashboard",
    name: "Logout",
    icon: <AiOutlineLogout />,
  },
  // {
  //   path: "/saved",
  //   name: "Saved",
  //   icon: <AiFillHeart />,
  // },
];



  

  

  const [islogin, setLogin] = useState(true);
  const [issidebar, setSidebar] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //alert(">>>>>>"+localStorage.getItem('roles'))
  
      if (localStorage.getItem('roles') === 'ROLE_USER') {
        //setLogin(false)
        setLogin(false)
          setSidebar(true)
        history('/Crm');
      }else {
        setSidebar(true)
        setLogin(false)
        history('/Dashboard');

  
      }
    } else {
      history('/Dashboard');
      
      setLogin(true)
      //setSidebar(false)
      //setSidebar
  
    }
  }, [])


  const [credentials, setCredentials] = useState({ username: "", password: "" })
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(process.env.REACT_APP_IP + "/authenticate", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username: credentials.username, password: credentials.password })
    });
    const json = await response.json()
    // console.log(json.token.length>10);
    //alert(">>>>"+json);
    if (json.token===null) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Wrong Credential",
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    }
    if (json.status === 401) {

      // alert("Invalid credentials");
      //props.showAlert("Invalid credentials", "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Wrong Credential",
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        showConfirmButton: false,
        timer: 2500
      })
    } else {

      if (json.token.length > 10) {
        // Save the auth token and redirect
        localStorage.setItem('token', json.token);
        localStorage.setItem('roles', json.roles);
        localStorage.setItem('uid', json.id);
        // history.push("/");
        //  history('/Dashboard');
        if (localStorage.getItem('roles') === 'ROLE_USER') {
          setLogin(false)
          setSidebar(true)
          history('/Crm');
        } else if (localStorage.getItem('roles') === 'ROLE_IVRUSER') {
          setSidebar(true)
          history('/Dashboard');
        } else {
          setSidebar(true)
          setLogin(false)
          history('/Dashboard');

        }

        //  props.showAlert("Successfully Login", "success");
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: "Successfully login",
          background: '#e9ecef',
          // title: 'Done Successfully ',
          //background: primary,
          showConfirmButton: false,
          timer: 1500
        })


      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Wrong Credential",
          background: '#e9ecef',
          // title: 'Done Successfully ',
          //background: primary,
          showConfirmButton: false,
          timer: 2500
        })
      }
    }

  }


  const handleLogout = async() => {
    //alert("called")  
    const response = await fetch(process.env.REACT_APP_IP+"/User/Logout", {
      method: 'POST',      
      headers: {           
          "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
  });
  const json = await response.json()
   // console.log(json);
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    setLogin(true)
    setSidebar(false)
   setCredentials({username: "", password: ""})
  // setCredentials({ ...credentials, '': '' })

    history('/Dashboard');
    //window.location.reload();
  
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: "Successfully Logout",
    background: '#e9ecef',
   // title: 'Done Successfully ',
   //background: primary,
    showConfirmButton: false,
    timer: 2500
    
  })
}




  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>

{islogin &&
<div className="row main-content bg-success text-center ">
			<div className="col-md-4 text-center company__info">
       {/* <img  src={logo} className="logom" />  */}
         Call-Center
				<span className="company__logo">
          
       
          <h2>
          

          {/* <span className="fa fa-users"></span> */}
        </h2></span>
				{/* <h4 className="company_title">Call-Center</h4> */}
			</div>
			<div className="col-md-8 col-xs-12 col-sm-12 login_form ">
				<div className="container-fluid">
					<div className="row mt-5">
						<h2> <RiLoginCircleLine className='me-3' size="22" />  Log In</h2>
					</div>
					<div className="row">
						<form  className="form-group p-2" onSubmit={handleSubmit}>
							<div className="row">
                 <div className='col-md-2 col-2 mt-5'>
                      <FaUser className='me-3' size="22" color="#008080" />
                </div> 
                
								<div className='col-md-10 col-10'>
                <input type="text" name="username" id="username" value={credentials.username}  
                onChange={onChange} className="form__input" placeholder="Username" required/>
               </div>
              
              </div>
							<div className="row">
              <div className='col-md-2 col-2 mt-5'>
              
              <RiLockPasswordFill className='me-3' size="22"  color="#008080"/>
                </div>
                <div className='col-md-10 col-10'>

                <input type="password" name="password" id="password" className="form__input"
                 onChange={onChange} value={credentials.password}  placeholder="Password" required/>
							

                </div>
								{/* <!-- <span className="fa fa-lock"></span> --> */}
								
              
              </div>
							{/* <div className="row">
								<input type="checkbox" name="remember_me" id="remember_me" className=""/>
								<label for="remember_me">Remember Me!</label>
							</div> */}
							<div className="row d-flex justify-content-center">
								<input type="submit" value="Submit" className="btn btn-successlogin"/>
							</div>
						</form>
					</div>
					{/* <div className="row">
						<p>Don't have an account? <a href="#">Register Here</a></p>
					</div> */}
				</div>
			</div>
		</div>

        }


   {issidebar &&
      <div className="main-container">
        <motion.div
          animate={{
            minWidth: isOpen ? "250px" : "50px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={isOpen ? "sidebar" : "hide"}
        >
          <div className="top_section">
            
            <AnimatePresence>
           
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  {localStorage.getItem('roles')==='ROLE_ADMIN'?
                
                <Link to="/" className="text-white ">
                  <FaHome className='me-2' size="25" /><b className=''>Call-Center</b>
                   {/* <h1>IVR</h1> */}
                  {/* <img  src={logo} className="logod" /> */}
                  </Link> 
                :<Link to="/Crm" className="">

                  {/* <img  src={logo} className="logod" /> */}

                  </Link> }

                  {localStorage.getItem('roles')==='ROLE_USER'?
                
                
                <div className='row'>
                        
                <Link to="/Crm" className="text-white ">
                     
                        
                          
                          
                    <FaHome size="25" className='float-start mx-2'/><div className='float-end mt-1'>Agent</div>
                     
                  </Link>
                  </div>
                   
                   
                :<Link to="/Crm" className="">

                  {/* <img  src={logo} className="logod" /> */}

                  </Link> }
                </motion.h1>
              )}
               {/* <NavLink to="/" className="link"><FaHome /> <b>Tech </b></NavLink>  */}
            </AnimatePresence>

            {/* <div className="bars" style={{cursor:'pointer'}}>
              <FaBars onClick={toggle} />
            </div> */}
          </div>
          {/* <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div> */}
          <section className="routes ">


            {localStorage.getItem('roles')==='ROLE_ADMIN'?
            routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <>
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                 
                  </>
                );
              }

              return (
                <>
                <NavLink
                onClick={route.name === 'Logout' ?
                handleLogout:''}
                  to={route.path}
                  key={index}
                  className={route.name === 'Logout' ?
                  "link bg-danger text-white mt-3 mb-5 p-2":"link "}
                  activeClassName="active"
                >
                  {route.name === 'Logout' ?
                       <div className="icon" >{route.icon}</div>
                    :   <div className="icon">{route.icon}</div>
                 }
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                       {route.name === 'Logout' ?
                       <div className='link_text' >{route.name}</div>
                       : route.name}
                        
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
                
                
                </>);
            })
            
         :""}

{localStorage.getItem('roles')==='ROLE_SUPERADMIN'?
            routessuperadmin.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <>
                <NavLink
                onClick={route.name === 'Logout' ?
                handleLogout:''}
                  to={route.path}
                  key={index}
                  className={route.name === 'Logout' ?
                  "link bg-danger text-white mt-3 mb-5 p-2 ":"link "}
                  activeClassName="active"
                >
                   {route.name === 'Logout' ?
                       <div className="icon" >{route.icon}</div>
                    :   <div className="icon">{route.icon}</div>
                 }
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                       {route.name === 'Logout' ?
                       <div className=''>{route.name}</div>
                       : route.name}
                        
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
                
                
                </>);
            })
            
         :""}




{localStorage.getItem('roles')==='ROLE_USER'?
            routesclient.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <>
                <NavLink

                 onClick={route.name === 'Logout' ?
                  handleLogout:''}
                  to={route.path}
                  key={index}
                  className={route.name === 'Logout' ?
                  "link bg-danger text-white ":"link "}
                  activeClassName="active"
                >
                   {route.name === 'Logout' ?
                       <div className="icon" >{route.icon}</div>
                    :   <div className="icon">{route.icon}</div>
                 }
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                       {route.name === 'Logout' ?
                       <div className='' >{route.name}</div>
                       : route.name}
                        
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
                
                
                </>);
            })
            
         :""}






{localStorage.getItem('roles')==='ROLE_AGENT'?
            routagent.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <>
                <NavLink
                onClick={route.name === 'Logout' ?
                handleLogout:''}
                  to={route.path}
                  key={index}
                  className={route.name === 'Logout' ?
                  "link bg-danger ":"link "}
                  activeClassName="active"
                >
                   {route.name === 'Logout' ?
                       <div className="icon" >{route.icon}</div>
                    :   <div className="icon">{route.icon}</div>
                 }
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                       {route.name === 'Logout' ?
                       <div className='' >{route.name}</div>
                       : route.name}
                        
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
                
                
                </>);
            })
            
         :""}





            {/* <NavLink to="/" className="link" onClick={handleLogout}> <AiOutlineLogout className='mx-1' size="20" />Logout</NavLink>  */}
          </section>
          
        </motion.div>

        <div className={isOpen ?
                  "mainmargin":"mainnomargin"}>
            <nav className="navbar navbar-dark bg-primary">
              
                  <div className="">
                       <a className="navbar-brand ms-3" href="#"> <FaBars onClick={toggle}  /></a> 
                       <a className="navbar-brand " href="#">Call-Center</a> 
                       {/* <a class=" float-end" href="#">{usernamed}</a>  */}
                      
                                  
                 </div>
                 
                 <div class="text-white float-end me-5">
                 {showNotification && (
                 

                  <NavLink to="/Callbackactive" className=" btn btn-warning btn-sm mx-3 button-flash"><FcCallback /> CallBack- {totalcallback} </NavLink>
                      
                     
                    )}
                       <FaUser size="20" /> {usernamed}
                      </div>
                
            </nav>
                  {children}
        </div>

      </div>
}


    </>
  );
};

export default SideBar;
