import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-date-picker';
import axios from 'axios'
import dateFormat from "dateformat";
import { motion } from 'framer-motion';

// import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from './AudioPlayer';
import Swal from 'sweetalert2';
import AudioDownloadButton from './AudioDownloadButton';
import { saveAs } from 'file-saver';
import { FaDownload } from 'react-icons/fa';
import { RotatingLines } from 'react-loader-spinner';



const FilterReport = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])


  useEffect(() => {
    document.title = "Filter Report"
  }, []);
  const [dispo, setDispo] = useState("NA")
  const [first, setFirst] = useState()
  const [second, setSecond] = useState("NA")
  const [third, setThird] = useState("NA")
  const [four, setFour] = useState("NA")
  const [five, setFive] = useState("NA")
  const [six, setFSix] = useState("NA")

  const [seven, setFSeven] = useState("NA")
  const [eight, setFEight] = useState("NA")
  const [nine, setFNine] = useState("NA")
  const [ten, setFTen] = useState("NA")

  const [downloadtrue, setDownloadtrue] = useState(false)

  const testdownload = async (e1, e2) => {
    try {
      const response = await fetch(process.env.REACT_APP_IP + `/User/download?folderandname=${e1}`, {
        method: 'GET',
        headers: {
          // 'Authorization': `Bearer ${token}`, // Add your token to the request headers
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = e2;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Error downloading CSV:', response.status);
        setDownloadtrue(false)
      }
      setDownloadtrue(false)
    } catch (error) {
      console.error('Error downloading CSV:', error);
      setDownloadtrue(false)
    }

  }

  const clicktocalfromreport = async (e) => {
    //e.preventDefault();
    //refclicktocall.current.click();
    //console.log(">>>>>>>>>>>>>>>>agentid>>>>>>>>>>>>>>>"+agentid)
    console.log(">>>>>>>>>>>>>>>>number>>>>>>>>>>>>>>>>" + e)
    var formdata = new FormData()
    // formdata.append('agentid', agentid)
    formdata.append('number', e)
    const response = await fetch(process.env.REACT_APP_IP + "/User/Clicktocallivr", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        //  'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    });
    const json = await response.json()
    console.log(json);
    if (json.type === "OK") {
      // props.showAlert(json.msg, "success");
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Success',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 2500
      })
    } else {
      //props.showAlert(json.msg, "danger");
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'error',
        background: '#e9ecef',
        // title: 'Done Successfully ',
        //background: primary,
        text: json.msg,
        showConfirmButton: false,
        timer: 4500
      })
    }

    //setAgentid("")
    // setNumber("")



  }
  const [startdate, setstartdateval] = useState(new Date());

  const [enddate, setEnddateval] = useState(new Date());

  const [sddate, setsddate] = useState("NA");
  const [eddate, seteddate] = useState("NA");


  const [campaignname, setCampaignname] = useState("0");

  const [keypress, setKeypress] = useState("NA");

  const [opennav, setopennav] = useState();
  const [listname, setList] = useState();


  const setstartdate = (event) => {
    //alert(event)
    setstartdateval(event)
    var sttt = dateFormat(event, "yyyy-mm-dd")



    setsddate(sttt)



  }

  const optionforDispo = async (e) => {
    // e.preventDefault();
    //alert(">>>>>>campidd>>>>"+e)
    // let iyear = parseInt(e);
    var formdata = new FormData()
    formdata.append('campid', e)
    const respp = await fetch(process.env.REACT_APP_IP + "/User/AllDispoListBycampid", {

      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('dispid');
    elelist.innerHTML = "" + ('<option value="NA">Select Dispo</option>');


    for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
        '<option  value="' + json[i]['dispo'] + '">' + json[i]['dispo'] + '</option>';
    }

    elelist.innerHTML = elelist.innerHTML + ('<option value="CALLBACK">CALLBACK</option>');
    elelist.innerHTML = elelist.innerHTML + ('<option value="NOANSWER">NOANSWER</option>');
    elelist.innerHTML = elelist.innerHTML + ('<option value="CANCEL">CANCEL</option>');
    elelist.innerHTML = elelist.innerHTML + ('<option value="CHANUNAVAIL">CHANUNAVAIL</option>');



  }

  const optionforlist = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllcampname", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('campnameid');
    elelist.innerHTML = "" + ('<option value="ALL">Select Campaign</option>');
    for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
        '<option  value="' + json[i]['id'] + '">' + json[i]['campname'] + '</option>';
    }






  }

  const [number, setNumber] = useState("NA");

  const onChangeNumber = (e) => {
    const inputValue = e.target.value;
    //const min = 1;
    //const max = 10;

    if (inputValue === '' || (inputValue.length < 11)) {
      setNumber(inputValue);
    } else {
      alert("10 Digit Allow Only")
    }
  };



  const optionforagent = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP + "/Admin/RestAllUserdrop", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = document.getElementById('agentid');

    elelist.innerHTML = "" + ('<option value="NA">ALL</option>');
    for (var i = 0; i < json.length; i++) {
      // POPULATE SELECT ELEMENT WITH JSON.
      elelist.innerHTML = elelist.innerHTML +
        '<option  value="' + json[i]['username'] + '">' + json[i]['id'] + ' | ' + json[i]['username'] + '</option>';
    }

  }




  // const download = (e1,e2) => {
  //  // alert(">>>>")
  //   const audioUrl = e1;
  //   const audioName = e2;

  //   alert("audioUrl>>>"+audioUrl+">>>>>audioName"+audioName)

  //   return <AudioDownloadButton audioUrl={audioUrl} audioName={audioName} />;
  // };

  const download = (url, filename) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, filename);
      })
      .catch((error) => {
        //console.error("Error downloading audio: ", error);
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'error',
          background: '#e9ecef',
          // title: 'Done Successfully ',
          //background: primary,
          text: "File UnAvailable",
          showConfirmButton: false,
          timer: 2500
        })
      });
  };


  useEffect(() => {
    optionforlist()
  }, [])


  useEffect(() => {
    optionforagent()
  }, [])


  const setenddate = (event) => {
    setEnddateval(event)
    var enddd = dateFormat(event, "yyyy-mm-dd")
    seteddate(enddd)

  }

  const toggleClass = () => {
    //alert("clicked");
    if (!opennav) {
      setopennav(true);
    } else {
      setopennav(false);
    }
    // alert("clicked"+opennav);

  }

  const filterreportdownload = async () => {
    setDownloadtrue(true)
    try {
      const response = await fetch(process.env.REACT_APP_IP + `/Admin/reportdownloadbycampid?campid=${campaignname}&agentid=${agentid}&startdate=${dateFormat(startdate, "yyyy-mm-dd")}&enddate=${dateFormat(enddate, "yyyy-mm-dd")}&number=${number}&dispo=${dispo}`, {
        method: 'GET',
        headers: {
          // 'Authorization': `Bearer ${token}`, // Add your token to the request headers
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Error downloading CSV:', response.status);
        setDownloadtrue(false)
      }
      setDownloadtrue(false)
    } catch (error) {
      console.error('Error downloading CSV:', error);
      setDownloadtrue(false)
    }

    //var startdate=dateFormat(startdate, "yyyy-mm-dd")
    //var enddate=dateFormat(enddate, "yyyy-mm-dd")

    //window.open(process.env.REACT_APP_IP + `/User/reportdownloadbycampid/obdreport.csv`, '_blank', 'noopener,noreferrer');
    // window.open(process.env.REACT_APP_IP + `/Admin/reportdownloadbycampid`+`?campid=`+campaignname+`&agentid=`+agentid+
    // `&startdate=`+dateFormat(startdate, "yyyy-mm-dd")+`&enddate=`+dateFormat(enddate, "yyyy-mm-dd")+`&number=`+number);


    // console.log("campaignname>>>>>>>"+campaignname)
    //console.log("keypress>>>>>>>"+keypress)
    // console.log("startdate>>>>>>>"+dateFormat(startdate, "yyyy-mm-dd"))
    //console.log("enddate>>>>>>>"+dateFormat(enddate, "yyyy-mm-dd"))



  }

  const filterreportsubmit = async (event) => {
    event.preventDefault();
    //alert(">>>>>"+agentid)
    //const DATE_FORMATER = require( 'dateformat' );
    //var datetimef = DATE_FORMATER( startdate, "yyyy-mm-dd" );


    console.log(">>>>startdate>>>>>>>" + dateFormat(startdate, "yyyy-mm-dd") + ">>>>>enddate>>>>>>>"
      + dateFormat(enddate, "yyyy-mm-dd") + ">>>>>>campaignname>>>>>>" + campaignname + ">>>>>>keypress>>>>>>" + keypress);


    getAllPosts()




  }

  const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
       
             
                <tbody>
                  {data.content.map(page => {
                    return (
                      <tr key={page.id} style={{ whiteSpace: "nowrap" }}>
                        <td>{page.id}</td>
                        <td>{page.fone}</td>
                        <td ><button className='btn btn-primary btn-sm disabled'>
                          <i className="fa fa-phone  disabled" onClick={(e) => clicktocalfromreport(page.fone)} ></i></button ></td>
                        <td><button className='btn btn-success btn-sm disabled'>
                          <a style={{ color: 'white' }} className="fa fa-whatsapp fa-lg  disabled" href={'https://web.whatsapp.com/send?phone=' + 91 + page.fone + '&text=Hii&source=&data=&app_absent=0'} target="_blank"></a>
                        </button></td>

                        <td>


                          {/* <AudioPlayer  src={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname} /> */}
                          {/* <AudioPlayer  src={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname} /> */}

                          <AudioPlayer src={process.env.REACT_APP_REC_LINK + page.userid + '/' + page.fold + '/' + page.recname} />


                        </td>
                        <td className='text-center'>

                          {/* <AudioDownloadButton audioUrl={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname} audioName={page.recname} /> */}

                          {/* <AudioDownloadButton audioUrl={process.env.REACT_APP_REC_LINK+'output.wav'} audioName={page.recname} /> */}


                          {/* <button className='btn btn-sm text-center' onClick={(e) =>download(process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname,page.recname)}>

                 <FaDownload  color="blue" />  

           </button>  */}

                          <button onClick={() => testdownload(page.userid + '/' + page.fold + '/' + page.recname, page.recname)}>
                            <FaDownload color="blue" />
                          </button>









                          {/*                     
                      <a  href={process.env.REACT_APP_REC_LINK+page.fold+'/'+page.recname} download={page.recname} target="_blank">
                                    <i className="fa fa-download fa-2x" style={{color:"blue"}} aria-hidden="true"></i> </a>
                     
                      */}

                        </td>
                        <td>{page.campaign}</td>
                        <td>{page.did}</td>
                        <td>{page.type}</td>
                        <td>{page.agentid} | {page.agentsip}</td>
                        <td >{page.dialtime}</td>
                        <td>{page.customerhanguptime}</td>
                        <td>{page.totalduration}</td>
                        <td>{page.pressedkey}</td>
                        <td>   <textarea name="" id="" cols="25" rows="1" value={page.remarks}></textarea>  </td>



                        <td>{page.dialstatus}</td>
                        <td>{page.dispoone}</td>
                        <td>{page.callbacktime}</td>
                        {first === '' ? '' : <td>{page.ftwo}</td>}
                        {second === '' ? '' : <td>{page.fthree}</td>}
                        {third === '' ? '' : <td>{page.ffour}</td>}
                        {four === '' ? '' : <td>{page.ffive}</td>}
                        {five === '' ? '' : <td>{page.fsix}</td>}
                        {six === '' ? '' : <td>{page.fseven}</td>}
                        {seven === '' ? '' : <td>{page.feight}</td>}
                        {eight === '' ? '' : <td>{page.fnine}</td>}
                        {nine === '' ? '' : <td>{page.ften}</td>}
                        {ten === '' ? '' : <td>{page.feleven}</td>}

                        {/* <td><i className="fa fa-phone btn btn-success " style={{marginLeft:"15px"}} onClick={() => clicktocallMethod(page.id)} ></i></td> */}



                        {/* <td></td>
                      <td></td>
                      <td><i className="fa fa-comment-o fa-xl btn btn-success " style={{marginLeft:"15px"}}  ></i></td>
                      <td><i className="fa fa-arrow-circle-o-up fa-2xl btn btn-info" style={{marginLeft:"15px"}}  ></i></td> */}


                      </tr>
                    );
                  })}
                </tbody>
             
      </>

    )

  }

  const getAllPosts = async () => {
    const res = await axios.get(process.env.REACT_APP_IP + `/User/checkreport?pageNumber=${offset - 1}&&
    size=10&&startdate=${sddate}&&enddate=${eddate}&&number=${number}&&agentid=${agentid}&&keypress=${keypress}&&campid=${campaignname}&&dispo=${dispo}`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {

    const offset = Math.ceil(event.target.value);
    setOffset(offset);

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  useEffect(() => {
    getAllPosts()
  }, [offset])

  const [agentid, setAgentid] = useState("NA");
  //const[listname,setList] = useState();

  const setagentidfun = (e) => {

    setAgentid(e.target.value)
    console.log("######agentid######" + e.target.value)

  }


  const testdata = async (e) => {

    console.log(">>>>>>>>>>>>>")
    optionforDispo(e.target.value)
    setCampaignname(e.target.value)
    var formdata = new FormData()
    formdata.append('campid', e.target.value)
    const response = await fetch(process.env.REACT_APP_IP + "/User/GetCampaignFieldBycampid", {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      },
      body: formdata
    });
    const json = await response.json()

    console.log(json.length)
    for (var i = 0; i < json.length; i++) {
      console.log(">>>>>>>>>" + i)
      if (i === 0) {
        //console.log(">>>>>inside>>>>"+i)
        setFirst(json[i].fieldlabel)
      }
      if (i === 1) {
        setSecond(json[i].fieldlabel)
      }
      if (i === 2) {
        setThird(json[i].fieldlabel)
      }
      if (i === 3) {
        setFour(json[i].fieldlabel)
      }
      if (i === 4) {
        setFive(json[i].fieldlabel)
      }
      if (i === 5) {
        setFSix(json[i].fieldlabel)
      }
      if (i === 6) {
        setFSeven(json[i].fieldlabel)
      }
      if (i === 7) {
        setFEight(json[i].fieldlabel)
      }
      if (i === 8) {
        setFNine(json[i].fieldlabel)
      }
      if (i === 9) {
        setFTen(json[i].fieldlabel)
      }



    }
    // console.log(">>>>>>>>"+first)


  }


  return (

    <>


      <div className='ms-1 me-3'>

        {/* <ul className="nav nav-tabs ">
  <li className="nav-item mx-1">
      <NavLink className="nav-link mybg-dark text-black"  to="/Report"><b>All Report</b></NavLink>
  </li>
  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/FilterReport"><b>Filter Report</b></NavLink>
  </li>

  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/Agentlog"><b>Agent Log</b></NavLink>
  </li>
  <li className="nav-item mx-1">
  <NavLink className="nav-link mybg-dark text-black"  to="/AgentPerformance"><b>AgentPerformance</b></NavLink>
  </li>
  
</ul> */}




        <div className=''>

          <div className={downloadtrue ? "text-center mt-5" : "text-center mt-5 hide"}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              visible={true}
            />

          </div>

          <div className='row mt-2'>

            <div className='col-md-3'>
              <div className='card'>

                <form onSubmit={filterreportsubmit} className=''>

                  <div className='card-header'>
                    <h4>Filter Report</h4>
                  </div>

                  {/* <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Campaign Name</label>
                        <select className="form-select" name="campaignname" 
                            onChange={(e) => setCampaignname(e.target.value)} >
                            <option value="All">ALL</option>
                           
                        </select>

                    </div> */}
                  <div className=" mt-1">
                    <label htmlFor="campaignname">Campaign Name</label>
                    {/* <select id="campnameid" className="form-select" name="campaignname"  onChange={(e) => setCampaignname(e.target.value)} required>
                            <option value="">-- Select Campaign --</option>
                            

                        </select> */}

                    <select id="campnameid" className="form-select" name="campaignname"
                      onChange={testdata} required>
                      <option value="">-- Select Campaign --</option>


                    </select>

                  </div>
                  <div className=" mb-1">
                    <label htmlFor="Number">Number</label>
                    <input type='number' value={number} className='form-control' onChange={onChangeNumber} />


                  </div>

                  <div className=" mb-1">
                    <label htmlFor="campaignname">Agent</label>
                    {/* <select id="campnameid" className="form-select" name="campaignname"  onChange={(e) => setCampaignname(e.target.value)} required>
                            <option value="">-- Select Campaign --</option>
                            

                        </select> */}

                    <select id="agentid" className="form-select" name="campaignname" onChange={setagentidfun} required>
                      <option value="">-- Select Agent --</option>


                    </select>

                  </div>
                  <div className=" mb-1">
                    <label htmlFor="sel1">Select Disposition:</label>
                    <select className="form-select" name="disp" id="dispid"


                      // onChange={(e) => setCamp(e.target.value)}
                      value={dispo}
                      onChange={(e) => setDispo(e.target.value)}
                    >
                      {/* <option value="NOANSWER">NOANSWER</option>
                                    <option value="CANCEL">CANCEL</option> */}


                    </select>

                  </div>

                  <div className="mb-1">
                    <label >KeyPress</label>
                    <select className="form-select" name="keypress" value={keypress}
                      onChange={(e) => setKeypress(e.target.value)} >
                      <option value="NA">NA</option>
                      <option value="All">ALL</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>

                    </select>

                  </div>

                  <div className="mb-1">
                    <label htmlFor="campaignname" >Start Date</label>

                    <DatePicker className="form-control" onChange={setstartdate} value={startdate} />

                  </div>

                  <div className="mb-1">
                    <label htmlFor="campaignname" >End Date</label>

                    <DatePicker className="form-control" onChange={setenddate} value={enddate} />

                  </div>

                  <div className="d-grid gap-2 mb-2 mt-2">
                    <input className="btn btn-primary " type="submit" value="Submit" />
                  </div>
                </form>

              </div>









            </div>

            <div className='col-md-9 '>
              <div className='card'>

             
              <div className='card-header'>
                     <h4 className='text-center'>Filter Report</h4>
              </div>


              {/* Display all the posts */}
              <div className='table-responsive'>
              <table className="table table-hover table-bordered checkoddata ignoreline ">
                <thead className="bg-darkblue text-white">
                  <tr>
                    <th>ID</th>

                    <th>Caller</th>
                    <th>Call</th>
                    <th>Wa</th>

                    <th>Recording</th>
                    <th>Download</th>

                    <th>Campaign</th>
                    <th>Did</th>
                    <th>Calltype</th>
                    <th>Agents</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Duration</th>
                    <th>Keypress</th>
                    <th>Remarks</th>
                    <th>Status</th>
                    <th>Dispo</th>
                    <th>CallBackTime</th>
                    {first === '' ? '' : <th>{first}</th>}
                    {second === 'NA' ? '' : <th>{second}</th>}
                    {third === 'NA' ? '' : <th>{third}</th>}
                    {four === 'NA' ? '' : <th>{four}</th>}
                    {five === 'NA' ? '' : <th>{five}</th>}
                    {six === 'NA' ? '' : <th>{six}</th>}
                    {seven === 'NA' ? '' : <th>{seven}</th>}
                    {eight === 'NA' ? '' : <th>{eight}</th>}
                    {nine === 'NA' ? '' : <th>{nine}</th>}
                    {ten === 'NA' ? '' : <th>{ten}</th>}
                    {/* <th>Circle</th>
                  <th>Operator</th>
                  <th>Remarks</th>
                  <th>SMS Suite</th> */}


                  </tr>
                </thead>
                {posts}
                </table>
                <div className='p-1 mt-1 mb-1'>
                  <button className='btn btn-primary btn-sm me-5 ' onClick={filterreportdownload}>Download</button>

                    Goto Pageno:<input type="text" className="col-md-1 mx-1" id="gotopage" aria-describedby="gotopage"
                      placeholder="gotopage" onChange={onInputChange} />

              </div>
              </div>

              </div>





              <div className='row mt-2'>
                <div className='col-md-3'>

                

                </div>
                <div className='col-md-6'>
                  <ReactPaginate
                    previousLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-left fa-lg"></i>}
                    nextLabel={<i style={{ color: 'blue' }} className="fa fa-arrow-right fa-lg"></i>}
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    breakLinkClassName={"break-mef"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={offset - 1}

                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />

                </div>
              </div>


            </div>


          </div>
        </div>




      </div>
    </>
  )
}

export default FilterReport
